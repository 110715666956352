import React from 'react'

import { graphql, PageProps } from 'gatsby'
// import { board } from '../../components/board/board';
import { cleanFileType } from '../../utils/helpers'
import Layout from '../../layouts/index'
import { SEO } from '../../components/seo'
import { Link } from '../../components/link'
import { DocumentArrowDownIcon, ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'
import { useMediaQuery } from 'react-responsive'

type AGMPageQuery = { [key: string]: any }

export default function AGM(props: PageProps<AGMPageQuery>) {
  const agmReports = props.data.agmReports.edges
  const agmPage = props.data.agmPage

  type Report = { [key: string]: any }
  type GroupedReports = { [key: string]: Report[] }

  /** object with year as key and array of reports as value */
  const groupedReports = agmReports.reduce((acc: GroupedReports, report: Report) => {
    const year: string = report.node.date.substring(0, 4)

    if (!acc[year]) {
      acc[year] = []
    }

    acc[year].push(report)

    return acc
  }, {}) as GroupedReports

  /** tuple of year and array of reports */
  type ReportsEntry = [string, Report[]]

  /**
   * array of reports grouped by years sorted in descending order (newest first).
   * inside each year group, reports are sorted by date in descending order
   */
  const reportsByYearEntries = Object.entries(groupedReports)
    .sort(([aYear], [bYear]) => Number(bYear) - Number(aYear))
    .map((reportsByYear) => {
      const [year, reports] = reportsByYear

      const sortedReports = reports.sort((a, b) => {
        const aDate = new Date(a.node.date)
        const bDate = new Date(b.node.date)

        return bDate.getTime() - aDate.getTime()
      })

      return [year, sortedReports] as ReportsEntry
    })

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  })

  return (
    <Layout
      location={props.location}
      heros={agmPage.hero}
      herosFallbackTitle="Board & Governance"
      herosFallbackSecondaryTitle={agmPage.title}
      tabs="governance"
    >
      <div className="container flex flex-col mx-auto mb-20 lg:flex-row ">
        <div className="w-full mx-auto prose dark:prose-dark lg:w-1/4 lg:order-2">
          <div
            dangerouslySetInnerHTML={{
              __html: agmPage.body.childMarkdownRemark.html
            }}
            className="mx-auto text-sm"
          />
        </div>
        <div className="w-full my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 lg:w-3/4 lg:order-1">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {agmReports.length > 0 ? (
              <>
                {isMobile ? (
                  <div>
                    {reportsByYearEntries.map(([year, reports]) => (
                      <>
                        {reports.map((result, index) => (
                          <div
                            key={result.node.date}
                            className="w-full my-0 px-6 pt-5 pb-6 border-b border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-900"
                          >
                            {index === 0 && (
                              <div className="flex py-2">
                                <span className="mt-0 mb-4 text-lg font-medium text-gray-400 dark:text-gray-600">
                                  {year}
                                </span>

                              </div>
                            )}
                            <div className="flex py-2">
                              <span className="w-20 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                                Title
                              </span>
                              <div className="text-sm text-gray-900 dark:text-gray-200">
                                {result.node.title}
                              </div>
                            </div>
                            <div className="flex py-2">
                              <span className="w-20 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                                Type
                              </span>
                              <Link
                                to={result.node.report.file.url}
                                className="flex items-center text-sm uppercase text-gray-900 dark:text-gray-200 hover:underline"
                              >
                                <DocumentArrowDownIcon
                                  className="w-6 h-6 mr-1 -ml-2 opacity-50"
                                  aria-hidden="true"
                                />
                                {cleanFileType(result.node.report.file.type)}
                              </Link>
                            </div>
                          </div>
                        ))}
                      </>
                    ))}
                  </div>
                ) : (
                  <div className="overflow-hidden border-b border-gray-200 shadow dark:border-gray-800 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-100 dark:bg-gray-800">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Year
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            View
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                        {reportsByYearEntries.map(([year, reports]) => (
                          <>
                            {reports.map((result, index) => (
                              <tr key={result.node.date}>
                                {index === 0 && (
                                  <td
                                    rowSpan={reports.length}
                                    className="px-6 py-4 uppercase text-base font-medium text-gray-400 dark:text-gray-600 dark:text-gray-200 whitespace-nowrap"
                                  >
                                    {year}
                                  </td>
                                )}
                                <td className="px-6 py-4 text-sm font-medium text-gray-900 capitalize dark:text-gray-200 whitespace-nowrap">
                                  {result.node.title}
                                </td>
                                <td className="px-6 py-4 uppercase text-sm font-medium text-gray-900 uppercase dark:text-gray-200 whitespace-nowrap">
                                  {cleanFileType(result.node.report.file.type)}
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-900 capitalize dark:text-gray-200 whitespace-nowrap">
                                  <Link
                                    to={result.node.report.file.url}
                                    className="flex items-center font-medium hover:underline"
                                  >
                                    <DocumentArrowDownIcon
                                      className="w-6 h-6 mr-1 opacity-50"
                                      aria-hidden="true"
                                    />{' '}
                                    View
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            ) : (
              <>
                {agmPage.noResults ? (
                  <div className=" items-center flex justify-center flex-col py-20 rounded bg-gray-100 dark:bg-gray-900 mx-auto text-lg text-black dark:text-white">
                    <ChatBubbleOvalLeftEllipsisIcon className="w-12 h-12 text-gray-500" />
                    <h6 className="flex justify-center mx-auto text-md text-gray-500">
                      {agmPage.noResults.noResults}
                    </h6>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query {
    agmPage: contentfulCustomPage(slug: { eq: "agm" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      noResults {
        noResults
      }
      hero {
        ...HeroFragment
      }
    }
    agmReports: allContentfulFinancialReport(
      filter: { type: { eq: "AGM" }, shown: { eq: true } }
      sort: { fields: [sticky, publishedDate], order: [DESC, DESC] }
    ) {
      edges {
        node {
          title
          date: publishedDate
          type
          report: file {
            file {
              type: contentType
              url
            }
          }
        }
      }
    }
  }
`
